import { motion } from "framer-motion";

const Door = () => {
    const draw = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.25,
                    type: "ease",                    duration: 2,
                    bounce: 0,
                },
                opacity: { delay: i * 0.25, duration: 0.01 },
            },
        }),
    };
    const draw1 = {
        hidden: { pathLength: 0, opacity: 0 },
        visible: (i: number) => ({
            pathLength: 1,
            opacity: 1,
            transition: {
                pathLength: {
                    delay: i * 0.25,
                    type: "ease",                    duration: 50,
                    bounce: 0,
                },
                opacity: { delay: i * 0.25, duration: 0.01 },
            },
        }),
    };
    return (
        <>
            <motion.svg
                width="412"
                height="552"
                viewBox="0 0 412 552"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                initial='hidden'
                whileInView='visible'
                viewport={{once: true}}
            >
                <motion.path
                    d="M59.9301 83.24H137.74M350.18 83.24H272.37M350.18 83.24H380.04M350.18 83.24V479.64H380.04V83.24M350.18 83.24V71.58H380.04V83.24M382.01 35.96L390.26 27.05H339.96L348.21 35.96M382.01 35.96H382H348.21M382.01 35.96H348.22V41.72H382.01V35.96ZM348.21 35.96H61.8901M353.61 479.64H316.26V550.52M353.61 479.64V550.52M353.61 479.64H410.67V550.52H353.61M353.61 550.52H316.26M316.26 550.52H1.00006M61.8901 35.96H28.1001M61.8901 35.96V41.72H28.1001V35.96M61.8901 41.73H347.91M28.1001 35.96L19.8501 27.05H70.1501L61.9001 35.96H28.1001ZM8.85006 15.67L1.00006 5.45M8.85006 15.67H399.69L408.27 5.45M8.85006 15.67H399.7V27.06H8.85006V15.67ZM1.00006 5.45H408.27M1.00006 5.45V1H408.27V5.45M30.0601 83.24H59.9201M30.0601 83.24V479.64H59.9201V83.24M30.0601 83.24V71.58H59.9201V83.24M56.5001 479.64H93.8501V550.52H56.5001M56.5001 479.64V550.52M56.5001 479.64H1.00006V550.52M56.5001 550.52H1.00006M248.8 81.5H161.3V88.74H142.96V46.96H267.13V88.74H248.8V81.5ZM166.55 86.74V93.98H137.73V41.73H272.38V93.98H243.56V86.74H166.55ZM350.18 163.21H380.04V180.79H350.18V163.21ZM350.18 154.04H380.04V163.2H350.18V154.04ZM355.42 46.96H374.8V66.34H355.42V46.96ZM350.18 41.73H380.04V71.59H350.18V41.73ZM30.0601 163.21H59.9201V180.79H30.0601V163.21ZM30.0601 154.04H59.9201V163.2H30.0601V154.04ZM35.3001 46.96H54.6801V66.34H35.3001V46.96ZM30.0601 41.73H59.9201V71.59H30.0601V41.73Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    variants={draw1}
                    custom={1}
                />
                <motion.path
                    d="M93.7201 525.68H100.93V523.31H309.18V525.68H316.39V115.98H93.7201V525.68Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    variants={draw}
                    custom={3}

                />
                <motion.path
                    d="M100.98 123.24H205.06M100.98 123.24V525.75M100.98 123.24H309.14M205.06 123.24V525.75M205.06 123.24H309.14M205.06 525.75H100.98M205.06 525.75H309.14M100.98 525.75H309.14M309.14 123.24V525.75M285.28 418.88V428.7H295.11V485.02H285.28V494.84H228.91V485.02H219.08V428.7H228.91V418.88H285.28ZM289.86 433.94V479.78H280.03V489.6H234.15V479.78H224.32V433.94H234.15V424.11H280.03V433.94H289.86ZM285.28 229.77V239.59H295.11V375.51H285.28V385.33H228.91V375.51H219.08V239.59H228.91V229.77H285.28ZM289.86 244.84V370.27H280.03V380.09H234.15V370.27H224.32V244.84H234.15V235.01H280.03V244.84H289.86ZM181.2 418.88V428.7H191.03V485.02H181.2V494.84H124.84V485.02H115.01V428.7H124.84V418.88H181.2ZM185.79 433.94V479.78H175.96V489.6H130.08V479.78H120.25V433.94H130.08V424.11H175.96V433.94H185.79ZM181.2 229.77V239.59H191.03V375.51H181.2V385.33H124.84V375.51H115.01V239.59H124.84V229.77H181.2ZM185.79 244.84V370.27H175.96V380.09H130.08V370.27H120.25V244.84H130.08V235.01H175.96V244.84H185.79ZM285.28 135.81V145.63H295.11V201.95H285.28V211.78H228.91V201.95H219.08V145.63H228.91V135.81H285.28ZM289.86 150.88V196.72H280.03V206.54H234.15V196.72H224.32V150.88H234.15V141.05H280.03V150.88H289.86ZM181.2 135.81V145.63H191.03V201.95H181.2V211.78H124.84V201.95H115.01V145.63H124.84V135.81H181.2ZM185.79 150.88V196.72H175.96V206.54H130.08V196.72H120.25V150.88H130.08V141.05H175.96V150.88H185.79Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    variants={draw1}
                    custom={3}

                />
            </motion.svg>
        </>
    );
};

export default Door;
