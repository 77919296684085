import "./footer.scss";

const Footer: React.FC = () => {
    return (
        <>
            
        </>
    );
};

export default Footer;
